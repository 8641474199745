// third-party
import { Chance } from 'chance';
import { filter } from 'lodash';

// project imports
import services from 'utils/mockAdapter';

// types
import { CartPortfolioStateProps, PortfolioCardProps } from 'types/cart';

const chance = new Chance();

let subtotal: number;
let result;

let latestPortfolios: CartPortfolioStateProps[];
let newPortfolio: CartPortfolioStateProps;
let inCartPortfolio: CartPortfolioStateProps[];
let oldSubTotal;
let amount;
let newShipping;

// ==============================|| MOCK SERVICES ||============================== //

services.onPost('/api/cart/add').reply((config) => {
  try {
    const { portfolio: portfolio, portfolios: portfolios } = JSON.parse(config.data);

    newPortfolio = { ...portfolio!, itemId: chance.timestamp() };
    subtotal = newPortfolio?.quantity * newPortfolio.offerPrice;

    inCartPortfolio = filter(portfolios, {
      id: newPortfolio.id,
      color: newPortfolio.color,
      size: newPortfolio.size
    });
    if (inCartPortfolio && inCartPortfolio.length > 0) {
      const newPortfolios = portfolios.map((item: CartPortfolioStateProps) => {
        if (newPortfolio?.id === item.id && newPortfolio?.color === item.color) {
          return { ...newPortfolio, quantity: newPortfolio.quantity + inCartPortfolio[0].quantity };
        }
        return item;
      });
      latestPortfolios = newPortfolios;
    } else {
      latestPortfolios = [...portfolios, newPortfolio];
    }

    return [200, { portfolios: latestPortfolios, subtotal }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/remove').reply((config) => {
  try {
    const { id, portfolios: portfolios } = JSON.parse(config.data);

    result = filter(portfolios, { itemId: id });
    subtotal = result[0].quantity * result[0].offerPrice;

    const newPortfolios = filter(portfolios, (item) => item.itemId !== id);

    return [200, { portfolios: newPortfolios, subtotal }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/update').reply((config) => {
  try {
    const { id, quantity, portfolios: portfolios } = JSON.parse(config.data);

    result = filter(portfolios, { itemId: id });
    subtotal = quantity! * result[0].offerPrice;
    oldSubTotal = 0;

    latestPortfolios = portfolios.map((item: PortfolioCardProps) => {
      if (id === item.itemId) {
        oldSubTotal = item.quantity * (item.apr || 0);
        return { ...item, quantity: quantity! };
      }
      return item;
    });

    return [200, { portfolios: latestPortfolios, oldSubTotal, subtotal }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/billing-address').reply((config) => {
  try {
    const { address } = JSON.parse(config.data);
    return [200, { billing: address! }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/discount').reply((config) => {
  try {
    const { total, code } = JSON.parse(config.data);
    amount = 0;
    if (total > 0) {
      switch (code) {
        case 'MANTIS50':
          amount = chance.integer({ min: 1, max: total < 49 ? total : 49 });
          break;
        case 'FLAT05':
          amount = total < 5 ? total : 5;
          break;
        case 'SUB150':
          amount = total < 150 ? total : 150;
          break;
        case 'UPTO200':
          amount = chance.integer({ min: 1, max: total < 199 ? total : 199 });
          break;
        default:
          amount = 0;
      }
    }

    return [200, { amount }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/shipping-charge').reply((config) => {
  try {
    const { shipping, charge } = JSON.parse(config.data);
    newShipping = 0;
    if (shipping > 0 && charge === 'free') {
      newShipping = -5;
    }
    if (charge === 'fast') {
      newShipping = 5;
    }

    return [200, { shipping: charge === 'fast' ? 5 : 0, newShipping, type: charge! }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/payment-method').reply((config) => {
  try {
    const { method } = JSON.parse(config.data);
    return [200, { method: method! }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/payment-card').reply((config) => {
  try {
    const { card } = JSON.parse(config.data);
    return [200, { card: card! }];
  } catch (err) {
    return [500, { message: 'Internal server error' }];
  }
});

services.onPost('/api/cart/reset').reply(200, {});
