import services from 'utils/mockAdapter';
import {portfolios} from 'data/pmp';
import {KeyedObject} from 'types/cart';
import {Portfolios} from 'types/pmp';

services.onPost('/api/portfolios/filter').reply((config) => {
    try {
        const {filter} = JSON.parse(config.data);

        if (filter.sort === 'high') {
            portfolios.sort((a: Portfolios, b: Portfolios) => Number(b.offerPrice) - Number(a.offerPrice));
        }

        if (filter.sort === 'low') {
            portfolios.sort((a, b) => Number(a.offerPrice) - Number(b.offerPrice));
        }

        if (filter.sort === 'popularity') {
            portfolios.sort((a, b) => Number(b.popularity) - Number(a.popularity));
        }

        if (filter.sort === 'discount') {
            portfolios.sort((a, b) => Number(b.discount) - Number(a.discount));
        }

        if (filter.sort === 'new') {
            portfolios.sort((a, b) => Number(b.new) - Number(a.new));
        }

        const results = portfolios.filter((portfolio: KeyedObject) => {
            let searchMatches = true;

            if (filter.search) {
                const properties = ['name', 'description', 'rating', 'salePrice', 'offerPrice', 'gender'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (portfolio[property] && portfolio[property].toString().toLowerCase().includes(filter.search.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    searchMatches = false;
                }
            }

            const genderMatches = filter.gender.length > 0 ? filter.gender.some((item: string) => item === portfolio.gender) : true;
            const categoriesMatches =
                filter.categories.length > 0 && filter.categories.some((category: string) => category !== 'all')
                    ? filter.categories.some((category: string) => portfolio.categories.some((item: string) => item === category))
                    : true;
            const colorsMatches =
                filter.colors.length > 0 ? filter.colors.some((color: string) => portfolio.colors.some((item: string) => item === color)) : true;

            const minMax = filter.price ? filter.price.split('-') : '';
            const priceMatches = filter.price ? portfolio.offerPrice >= minMax[0] && portfolio.offerPrice <= minMax[1] : true;
            const ratingMatches = filter.rating > 0 ? portfolio.rating >= filter.rating : true;

            return searchMatches && genderMatches && categoriesMatches && colorsMatches && priceMatches && ratingMatches;
        });

        return [200, results];
    } catch (err) {
        console.error(err);
        return [500, {message: 'Internal server error'}];
    }
});