// third-party
import {createSlice} from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import {dispatch} from "../index";

import {Address, DefaultRootStateProps, PortfoliosFilter} from "types/pmp";
import getJobId from "../../hooks/getJobId";

const initialState: DefaultRootStateProps["portfolio"] = {
    error: null,
    jobId: "",
    description: "",
    portfolios: [],
    align: {},
    portfolio: null,
    botConfig: null,
    botConfigGroupingPerf: null,
    customPortfolioStyle: null,
    performanceSummary: null,
    portfolioFundamental: null,
    customPortfolioGrouping: null,
    statsSummary: null,
    portfolioCumulPerf: null,
    privateMarketPlaceMeta: null,
    portfolioGPT: null,
    scores: null,
    benchmarkScores: null,
    pmpAlign:[],
    relatedPortfolios: [],
    reviews: [],
    addresses: [],
    userInfo: []
};

function getTopFrequencies(number: any, frequency: any) {
    let counter = number.reduce(
        (counter: any, key: any) => {
            counter[key] = 1 + counter[key] || 1;
            return counter;
        }, {});
    let frequencyMap = Object.entries(counter).sort((a: any, b: any) => b[1] - a[1]).slice(0, frequency); // choose number of most frequent
    let f: any = [];
    frequencyMap.map((a, b) => {
        f.push(a[0]);
    });
    return f;
}

function getNumbersInPosition(mega: any, number: number) {
    return mega.map((a: any) => {
        return a.winning_numbers.split(" ")[number];
    });
}

const slice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        getPortfoliosSuccess(state, action) {
            state.portfolios = action.payload;
        },
        getDescriptionSuccess(state, action) {
            state.description = action.payload;
        },

        getPerformanceSummarySuccess(state, action) {
            state.performanceSummary = action.payload;
        },
        getStatsSummarySuccess(state, action) {
            state.statsSummary = action.payload;
        },
        getCustomPortfolioCumulPerfSuccess(state, action) {
            state.portfolioCumulPerf = action.payload;
        },
        getPrivateMarketPlaceMetaSuccess(state, action) {
            state.privateMarketPlaceMeta = action.payload;
        },
        getPortfolioFundamentalSuccess(state, action) {
            state.portfolioFundamental = action.payload;
        },
        getPortfolioGPTSuccess(state, action) {
            state.portfolioGPT = action.payload;
        },
        filterPortfoliosSuccess(state, action) {
            state.portfolios = action.payload;
        },

        getPortfolioSuccess(state, action) {
            state.portfolio = action.payload;
        },
        getBotConfigSuccess(state, action) {
            state.botConfig = action.payload;
        },
        getBotConfigGroupingPerfSuccess(state, action) {
            state.botConfigGroupingPerf = action.payload;
        },
        getCustomPortfolioStyleSuccess(state, action) {
            state.customPortfolioStyle = action.payload;
        },
        getCustomPortfolioGroupingSuccess(state, action) {
            state.customPortfolioGrouping = action.payload;
        },

        getScoresSuccess(state, action) {
            state.scores = action.payload;
        },

        getBenchmarkScoresSuccess(state, action) {
            state.benchmarkScores = action.payload;
        },

        getAlignSuccess(state, action) {
            state.align = action.payload;
        },

        getRelatedPortfoliosSuccess(state, action) {
            state.relatedPortfolios = action.payload;
        },

        getPortfolioReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },
       
        getAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },
       
        addAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        editAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        getUserInfoSuccess(state, action) {
            state.userInfo = action.payload
        },
        getPMPSuccess(state,action){
            state.pmpAlign= action.payload
        }
    }
});

export default slice.reducer;


export function getCustomPortfolioDescription(token: any, status: any, portfolioID: any) {
    return async () => {
        try {
            let jobID = await getJobId(token);
            await jobID && await fetch(`https://api.versaquant.com:5000/run3/pc.getCustomPortfolioDescription/["${status}","${portfolioID}","${jobID}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(async response => {
                {
                    response.json().then(async result => {
                        dispatch(slice.actions.getDescriptionSuccess(result));
                    });
                }
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export const getUserInfo = (token: any) => async (dispatch: any) => {
    try {
        const response = await fetch(`https://api.versaquant.com:5001/user/getUserInfo/`, {
        // const response = await fetch(`http://localhost:5001/user/getUserInfo/`, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`,
                "accept": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const data = await response.json();
        let metadata = data.user_metadata;
        let stripeUserId = data?.app_metadata?.data?.object?.customer;

        const transformedData: any = {
            designation: metadata.designation,
            firstname: metadata.firstname,           // Update based on the actual properties
            lastname: metadata.lastname,                   // No lastname in your example
            email: metadata.email || '',
            dob: metadata.dob,
            countryCode: metadata.countryCode,
            phoneNumber: metadata.phoneNumber,
            address1: metadata.address1,
            address2: metadata.address2,
            state: metadata.state,
            zip: metadata.zip,
            country: metadata.country,
            note: metadata.note,
            stripe_user_id: stripeUserId
        };

        if (stripeUserId) {
            const stripeResponse = await fetch(
                `https://api.versaquant.com:5001/stripe/create_portal_session/${stripeUserId}`,
                // `http://localhost:5001/stripe/create_portal_session/${stripeUserId}`,
                {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`,
                        "accept": "application/json",
                    },
                }
            );

            const stripeData = await stripeResponse.json();
            transformedData.stripe_portal_session = stripeData.url;
        } else {
            console.log('No Stripe User ID found.');
        }
        dispatch(slice.actions.getUserInfoSuccess(transformedData));
    } catch (error) {
        console.error('Fetch error:', error);
    }
};


export function getCustomPortfolioAllocations(token: any, portfolioId: any) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            if (!portfolioId) {
                return await jobId && fetch(`https://api.versaquant.com:5000/run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(response => {
                    return response.json().then(async result => {
                        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioAllocations/["pmp", "${result.portList[0].PortfolioID}","${jobId}"]`;
                        return await fetch(url, {
                            mode: "cors",
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Authorization": `Bearer ${token}`
                            }
                        }).then(res => {
                            res.json().then(r => {
                                dispatch(slice.actions.getBotConfigSuccess(r));
                            });
                        });
                    }).catch(e => {
                        dispatch(slice.actions.hasError(e));
                    });
                }).catch(e => {
                    console.log(e);
                });
            } else {
                let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioAllocations/["pmp", "${portfolioId}","${jobId}"]`;
                return await fetch(url, {
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(res => {
                    res.json().then(r => {
                        dispatch(slice.actions.getBotConfigSuccess(r));
                    });
                });
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getPerformanceSummary(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioPerformanceStats/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(r => {
            r.json().then(res => {
                dispatch(slice.actions.getPerformanceSummarySuccess(res));
            });
        });
    };
}


export function getPortfolioFundamental(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioFundamentals/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(res => {
                dispatch(slice.actions.getPortfolioFundamentalSuccess(res));
            });
        });
    };
}

export function getStatsSummary(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioBmkRiskStats/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getStatsSummarySuccess(r));
            });
        });
    };
}

export function getCustomPortfolioCumulPerf(token: any, status: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioCumulPerf/["${status}","${portfolioID}","${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getCustomPortfolioCumulPerfSuccess(r));
            });
        }).then((e) => {
            console.log(e);
        });
    };
}

export function addCustomWatchlist(token: any, portfolioID: any, tag: any) {
    return async () => {
        let jobid = portfolioID.split('_')[1];
        let url = `https://api.versaquant.com:5000/run3/pc.addCustomPortfolioToWatchList/["${portfolioID}","${jobid}","${tag}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                console.log("added to watchlist successfully");
                ;
            });
        }).then((e) => {
            console.log(e);
        });
    }
}
export function RTCustomDaily(token: any, portfolioID: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pc.runRTCustomBTDaily/["${portfolioID}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                console.log("updated  portfolio successfully");
                ;
            });
        }).then((e) => {
            console.log(e);
        });
    }
}


export function getPrivateMarketPlaceMeta(token: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pd.getPrivateMarketPlaceMeta/["${jobId}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(res => {
            res.json().then(r => {
                dispatch(slice.actions.getPrivateMarketPlaceMetaSuccess(r));
            });
        });
    };
}

export function getExplainAlign(token: any, input: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pa.explainAlign/["${input}"]`;
        const res = await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res => {
                res.json().then(r => {
                    dispatch(slice.actions.getPortfolioGPTSuccess(r));
                });
            });
    };
}

export function getPrivateMarketPlace(token: any) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`https://api.versaquant.com:5000/run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList?.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    dispatch(slice.actions.getPortfoliosSuccess(portList));
                });
            }).catch(e => {
                console.log(e);
            });

        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function genPrivateMarketPlace  (
    token: any,
    time: number | null,
    risk: number | null,
    _styles: any,
    _initialInvestment: any,
    _investmentPurpose: any,
    _accountType: any,
   
)  {
    return async () => {
        if (
            time !== null &&
            risk !== null &&
            _styles !== null &&
            _initialInvestment !== null &&
            _investmentPurpose !== null &&
            _accountType !== null
        ) {
            try {
                let styles = JSON.stringify(_styles);
                let initialInvestment = JSON.stringify(_initialInvestment);
                let investmentPurpose = JSON.stringify(_investmentPurpose);
                let accountType = JSON.stringify(_accountType);
                
                const response = await fetch(
                    `https://api.versaquant.com:5000/run3/pd.genPrivateMarketPlace/[${time},${risk / 10},${styles},${initialInvestment},${investmentPurpose},${accountType}]`,
                    {
                        mode: "cors",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${token}`
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();

                dispatch(slice.actions.getPMPSuccess(result)); // Assuming slice is defined somewhere

            } catch (error) {
                console.log("error is " + error);
                dispatch(slice.actions.hasError(error));
            }
        }
    };
};

export function getBotConfigGroupingsPerf(token: any, Project: any, Portfolio: any, Type: any) {
    return async () => {
        let url = `https://api.versaquant.com:5000/run3/pq.getPortGroupingsPerf/["${Project}","${Portfolio}","${Type}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getBotConfigGroupingPerfSuccess(result));
            });
        });
    };
}


export function getCustomPortfolioStyles(token: any, simType: any, portfolioID: any) {
    return async () => {
        let jobId = await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioStyles/["${simType}","${portfolioID}","${jobId}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getCustomPortfolioStyleSuccess(result));
            });
        });
    };
}

export function getCustomPortfolioGroupings(token: any, portfolioID: any, type: any) {
    return async () => {
        let jobId = await await getJobId(token);
        let url = `https://api.versaquant.com:5000/run3/pc.getCustomPortfolioGroupings/["pmp", "${portfolioID}","${jobId}","${type}"]`;
        await fetch(url, {
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.getCustomPortfolioGroupingSuccess(result));
            });
        });
    };
}


export function filterPortfolios(filter: PortfoliosFilter) {
    return async () => {
        try {
            const response = await axios.post("/api/portfolios/filter", {filter});
            dispatch(slice.actions.filterPortfoliosSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPortfolio(id: string | undefined, token: string | null | undefined) {
    return async () => {
        try {   
            let jobId = await getJobId(token);
            await jobId && fetch(`https://api.versaquant.com:5000/run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    let port = portList.find((p: any) => p.PortfolioID === id
                    );
                    dispatch(slice.actions.getPortfolioSuccess(port));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getScoresAsync(id: string | undefined, token: string | null | undefined) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`https://api.versaquant.com:5000/run3/pd.getPrivateMarketPlace/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    let portList = result.portList;
                    portList?.map((port: any) => {
                        port.image = "port.png";
                        port.isStock = true;
                    });
                    let port = portList?.find((p: any) => p.PortfolioID === id
                    );
                    dispatch(slice.actions.getScoresSuccess(port?.Scores.Components));
                    dispatch(slice.actions.getBenchmarkScoresSuccess(port?.Scores_BenchmarkEquities.Components));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getClientAlign(token: string | null | undefined) {
    return async () => {
        try {
            let jobId = await getJobId(token);
            await jobId && fetch(`https://api.versaquant.com:5000/run3/pa.getClientAlign/["${jobId}"]`, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.getAlignSuccess(result));
                });
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRelatedPortfolios(id: string | undefined) {
    return async () => {
        try {
            const response = await axios.post("/api/portfolio/related", {id});
            dispatch(slice.actions.getRelatedPortfoliosSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPortfolioReviews() {
    return async () => {
        try {
            const response = await axios.get("/api/review/list");
            dispatch(slice.actions.getPortfolioReviewsSuccess(response.data.portfolioReviews));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAddresses() {
    return async () => {
        try {
            const response = await axios.get("/api/address/list");
            dispatch(slice.actions.getAddressesSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addAddress(address: Address) {
    return async () => {
        try {
            const response = await axios.post("/api/address/new", address);
            dispatch(slice.actions.addAddressSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editAddress(address: Address) {
    return async () => {
        try {
            const response = await axios.post("/api/address/edit", address);
            dispatch(slice.actions.editAddressSuccess(response.data.address));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
