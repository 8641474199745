// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    CalendarOutlined,
    CustomerServiceOutlined, DashboardOutlined,
    MessageOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    ShopOutlined,
    BlockOutlined,
    MonitorOutlined, 
    FlagOutlined 
} from '@ant-design/icons';

// type
import {NavItemType} from 'types/menu';

// icons
const icons = {
    CalendarOutlined,
    DashboardOutlined,
    CustomerServiceOutlined,
    MessageOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    ShopOutlined,
    BlockOutlined,
    MonitorOutlined, 
    FlagOutlined 
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
    id: 'group-applications',
    // title: <FormattedMessage id="applications"/>,
    type: 'group',
    children: [
        // {
        //   id: 'chat',
        //   title: <FormattedMessage id="chat" />,
        //   type: 'item',
        //   url: '/apps/chat',
        //   icon: icons.MessageOutlined,
        //   breadcrumbs: false
        // },
        // {
        //   id: 'calendar',
        //   title: <FormattedMessage id="calendar" />,
        //   type: 'item',
        //   url: '/apps/calendar',
        //   icon: icons.CalendarOutlined
        // },
        {
            id: 'private-marketplace',
            title: <FormattedMessage id="private-marketplace"/>,
            type: 'item',
            icon: icons.ShoppingCartOutlined,
            url: '/apps/pmp/portfolios',
            // children: [
            //     {
            //         id: 'portfolios',
            //         title: <FormattedMessage id="portfolios"/>,
            //         type: 'item',
            //         url: '/apps/pmp/portfolios'
            //     },
            //     {
            //         id: 'portfolio-details',
            //         title: <FormattedMessage id="portfolio-details"/>,
            //         type: 'item',
            //         url: '/apps/pmp/portfolio-details/1',
            //         breadcrumbs: false,
            //     },
            //     {
            //         id: 'portfolio-list',
            //         title: <FormattedMessage id="portfolio-list"/>,
            //         type: 'item',
            //         url: '/apps/pmp/portfolio-list',
            //         breadcrumbs: false
            //     },
            // ]
        },
       
        
    ]
};

export default applications;
