// third-party
import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import portfolioReducer from './portfolio';
import stylesReducer from './styles';
import cartReducer from './cart';
import watchlistReducer from './watchlist'
import broker from "./broker";
import Notifications from './Notifications';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    chat,
    calendar,
    menu,
    snackbar,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'mantis-ts-'
        },
        cartReducer
    ),
    portfolio: portfolioReducer,
    styles: stylesReducer,
    watchlist:watchlistReducer,
    broker: broker,
    notifications:Notifications,
    
});

export default reducers;
