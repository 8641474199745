import { useEffect, useState } from 'react';
import useAuth from '../sections/apps/profiles/user/useAuth';

const useJobId = () => {
    const key = 'jobId';
    const {token} = useAuth();

    const [data, setData] = useState(() => {
        // Try to get jobId from local storage
        const storedJobId = localStorage.getItem(key);

        if (storedJobId && storedJobId !== "undefined") {
            try{
                return JSON.parse(storedJobId);
            } catch (error) {
                
                console.error("An error occurred while parsing JSON:", error);
                return null;
            }
        };    
        // If no jobId in local storage or jobId is "undefined", return null
        return null;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.versaquant.com:5000/run3/pd.getJobInfo/[]', {
                    mode: 'cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                // Assume the response is an array, and the jobId is in the first object
                if (Array.isArray(data) && data.length > 0 && data[0].jobID) {
                    const jobId = data[0].jobID;

                    // Save jobId to local storage
                    localStorage.setItem(key, JSON.stringify(jobId));

                    // Update state
                    setData(jobId);
                }
            } catch (error) {
                console.error('Fetching job ID failed:', error);
            }
        };

        // Fetch data if there's no jobId in local storage
        if (!data) {
            fetchData();
        }
    }, [token]); // fetch again only when token changes

    return data;
};

export default useJobId;
