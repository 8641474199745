import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
  ListItem,
  IconButton as MuiIconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import useAuth from 'sections/apps/profiles/user/useAuth';
import { useDispatch, useSelector } from 'store';
import { BellOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { getFetchNotifications, readFetchNotifications } from 'store/reducers/Notifications';

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { token, user } = useAuth();
  const dispatch = useDispatch();
  const anchorRef = useRef<any>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [fetchSummaryData, setFetchSummaryData] = useState([]);
  const { fetchNotification ,fetchReadNotification} = useSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getFetchNotifications(token));
  }, [token]);

  useEffect(() => {
    if (Array.isArray(fetchNotification)) {
      // Calculate unread count
      const count = fetchNotification.filter((msg: any) => !msg.read).length;
      setUnreadCount(count);
    } else {
      setUnreadCount(0); // Or handle the case where fetchNotification is not an array
    }
  }, [fetchNotification]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (!open) {
      dispatch(getFetchNotifications(token));
    }
  };
  useEffect(() => {
    if (fetchReadNotification) {
      setFetchSummaryData(fetchReadNotification);
    }
  }, [fetchReadNotification])


  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (notificationId: any) => {
    if (user) {
      dispatch(readFetchNotifications(token, notificationId)).then(() => {
        dispatch(getFetchNotifications(token));
      });
    }
  };

  const handleRead = (id: any) => {
    // Update the read status locally
    const updatedMessages = fetchNotification.map((msg: any) =>
      msg.id === id ? { ...msg, read: true } : msg
    );
    setUnreadCount((prevCount) => prevCount - 1); // Decrease unread count
    // Call API to mark as read
    
     
    
  };


  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={unreadCount} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {unreadCount > 0 && (
                        <Tooltip title="Mark all as read">
                          <IconButton color="success" size="small" onClick={() => setUnreadCount(0)}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                    <Paper elevation={3} style={{ padding: '20px', maxHeight: '400px', overflow: 'auto' }}>
                    <Typography variant="h4">Messages</Typography>
                    <List>
                      {fetchNotification.map((message: any) => (
                        <ListItem key={message.id}>
                          <ListItemText
                            primary={
                              <Link
                                to={`/apps/watchlist/Portfolio-Details/${message.PortfolioID}`}
                                onClick={() => handleRead(message.id)}
                                style={{ color: message.read ? 'green' : 'blue' }}
                              >
                                {message.PortfolioTag}
                              </Link>
                            }
                            secondary={message.Message}
                          />
                          <MuiIconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(message.notificationEventID)}
                          >
                            <DeleteOutlined />
                          </MuiIconButton>
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
