import {createSlice} from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import {dispatch} from "../index";

import {DefaultRootStateProps, NotifictionStateProps } from "types/pmp";
const initialState: DefaultRootStateProps["notifications"] = {
    error:  null,
    fetchNotification:[],
    fetchReadNotification:[],
    thresholdValue:[],
    errorThreshold:[],
    modifyThresholdValue:[],
    createThreshold:[],
    fetchKpiValue:[],
    deleteThreshold:[],
    };
   
  
    
    
    const slice = createSlice({
        name: "notifications",
        initialState,
        reducers: {
            hasError(state, action) {
                state.error = action.payload;
            },
            getNotificationsSuccess(state,action){
                state.fetchNotification=action.payload;
               

            },
            getReadNotificationsSuccess(state,action){
                state.fetchReadNotification=action.payload;
               

            },
            getThresholdSucess(state,action){
                state.thresholdValue=action.payload;
            },
            getThresholdFailure(state,action){
                state.errorThreshold=action.payload;
            },
            createThresholdSuccess(state,action){
                state.createThreshold=action.payload
            },
            fetchKpiIdSuccess(state,action){
                state.fetchKpiValue=action.payload;
            },
            modifyThresholdSuccess(state , action){
                state.modifyThresholdValue=action.payload;
            },
            deleteThresholdSuccess(state,action){
                state.deleteThreshold=action.payload;
            }
            
        }
    });
    export default slice.reducer;
    export function getFetchNotifications(token: any) {
        return async () => {
            
            let url = `https://api.versaquant.com:5003/api/v1/notifications?all=false`;
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                    
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.getNotificationsSuccess(result));
                });
            });
        };
    }
    
    export function readFetchNotifications(token: any, notificationEventIDs: string[]) {
        return async (dispatch: any) => {
            try {
                const response = await fetch('https://api.versaquant.com:5003/api/v1/notifications/read', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({notification_event_ids: [notificationEventIDs] })
                });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Server error: ', errorData);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const result = await response.json();
                dispatch(slice.actions.getReadNotificationsSuccess(result));
    
            } catch (error) {
                console.error('Fetch error: ', error);
            }
        };
    }
    

    export function getThresholdPortfolio(token: any, portfolioID: any, user_id: any) {
        return async (dispatch: any) => {
            let url = `https://api.versaquant.com:5003/api/v1/thresholds/${portfolioID}?user_id=${user_id}`;
    
            try {
                const response = await fetch(url, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${token}`
                    }
                });
    
                const result = await response.json();
    
                if (response.ok) {
                    dispatch(slice.actions.getThresholdSucess(result));
                } else {
                    if (result.detail === "Portfolio not found for the user") {
                        dispatch(slice.actions.getThresholdFailure("Portfolio not found for the user"));
                    } else {
                        dispatch(slice.actions.getThresholdFailure("An error occurred while fetching the portfolio data"));
                    }
                }
            } catch (error) {
                dispatch(slice.actions.getThresholdFailure("An error occurred while fetching the portfolio data"));
            }
        };
    }
    
    
    
    export function modifyThreshold(token: any, threshold_id: any, threshold: any) {
        return async (dispatch: any) => {
            try {
                const response = await fetch(`https://api.versaquant.com:5003/api/v1/thresholds/${threshold_id}`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(threshold)
                });
    
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Server error: ', errorData);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const result = await response.json();
                dispatch(slice.actions.modifyThresholdSuccess(result));
    
            } catch (error) {
                console.error('Fetch error: ', error);
                
            }
        };
    }
    export function CreateNewThreshold(token: any,threshold:any) {
        return async () => {
            try {
                let response = await fetch(`https://api.versaquant.com:5003/api/v1/thresholds`, {
                    method: 'POST',
                    mode: "cors",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(threshold)
                });
   
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
   
                let result = await response.json();
   
                dispatch(slice.actions.createThresholdSuccess(result));
   
            } catch (error) {
                console.error("error is ", error);
                dispatch(slice.actions.hasError(error));
            }
        }
    }
   
    export function fetchKpiId(token: any)  {
        return async (dispatch: any) => {
            let url = `https://api.versaquant.com:5003/api/v1/latest-kpi-ids`;
   
            await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`
                }
            }).then(response => {
                response.json().then(result => {
                    dispatch(slice.actions.fetchKpiIdSuccess(result));
                });
            });
        };
       
    };
  
export function deleteThreshold(token: any, thresholdId: any) {
    return async (dispatch: any) => {
        const url = `https://api.versaquant.com:5003/api/v1/thresholds/${thresholdId}`;

        await fetch(url, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        }).then(response => {
            response.json().then(result => {
                dispatch(slice.actions.deleteThresholdSuccess(result));
            } )
           
        });
    };
}
