import React, { useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { dispatch, useSelector } from 'store';
import error404 from 'assets/images/maintenance/Error404.png';
import TwoCone from 'assets/images/maintenance/TwoCone.png';
import { getUserInfoError } from 'store/reducers/watchlist';
import useAuth from 'sections/apps/profiles/user/useAuth';

function Error404() {
  const { userInfoError } = useSelector((state) => state.watchlist);
  const history = useNavigate();
  const {token} = useAuth();
  const handleClick = () => {
    history('/dashboard/analytics');
  };
  // const trimmedComponentStack = JSON.stringify(componentStack).substring(0, 200);
  // // Access and use the failedUrl and failedApi here
  // console.log('Error Object:', {componentStack});
  useEffect(() => {
    dispatch(getUserInfoError(token));
  }, [token]); 

  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', pt: 1.5, pb: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box sx={{ width: { xs: 250, sm: 590 }, height: { xs: 130, sm: 300 } }}>
                <img src={error404} alt="mantis" style={{ width: '100%', height: '100%' }} />
              </Box>
            </Grid>
            <Grid item sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 60,
                  left: -40,
                  width: { xs: 130, sm: 390 },
                  height: { xs: 115, sm: 330 },
                }}
              >
                <img src={TwoCone} alt="mantis" style={{ width: '100%', height: '100%' }} />
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography variant="h1">Page Not Found</Typography>
            <Typography color="textSecondary" align="center" sx={{ width: { xs: '73%', sm: '61%' } }}>
              Sorry, the service is temporarily unavailable. Please try again later or
              Navigate back to familiar grounds or seek our assistance at
              support@versaquant.com. We're here to help!
            </Typography>
            <Typography variant="body2">UserID: {userInfoError && userInfoError.sub}</Typography>
            {/* <Typography variant="body2">Error Object: {JSON.stringify(trimmedComponentStack)}</Typography> */}         
                                    <Button variant="contained"  color="primary" onClick={handleClick}>
                                    Go back to home
                                    </Button>
                                    
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Error404;
