import {lazy} from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
//import StockInfo from './stock-info';






// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

// render - widget
const WidgetAlign = Loadable(lazy(() => import('pages/widget/align/align')));
const Views = Loadable(lazy(() => import('pages/widget/views')));
const ExploreBlocks = Loadable(lazy(() => import('pages/widget/ViewArea')));
const WidgetStatistics = Loadable(lazy(() => import('pages/widget/statistics')));
const WidgetData = Loadable(lazy(() => import('pages/widget/data')));
const WidgetChart = Loadable(lazy(() => import('pages/widget/chart')));

// render - applications
const AppChat = Loadable(lazy(() => import('pages/apps/chat')));
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')));


const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
// const StripeSettings = Loadable(lazy(() => import('sections/apps/profiles/user/StripeProfile')));
const StripeSettings = Loadable(lazy(() => import('sections/apps/profiles/user/StripeProfile')));
const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
const ProfileUserList = Loadable(lazy(() => import('pages/apps/profiles/user-list')));
const ProfileUserCard = Loadable(lazy(() => import('pages/apps/profiles/user-card')));

const AppPmpPortfolios = Loadable(lazy(() => import('pages/apps/pmp/portfolios')));
const AppPmpChatAdvisor=Loadable(lazy(() => import('pages/apps/pmp/ChatAdvisor')));
const AppPmpPortfolioDetails = Loadable(lazy(() => import('pages/apps/pmp/portfolio-details')));
const AppPmpPortfolioList = Loadable(lazy(() => import('pages/apps/pmp/portfolios-list')));
const AppPmpCheckout = Loadable(lazy(() => import('pages/apps/pmp/checkout')));
const AppPmpAddPortfolio = Loadable(lazy(() => import('pages/apps/pmp/add-portfolio')));
const AppPmpAddToWatchList=Loadable(lazy(() => import('sections/apps/pmp/portfolio-details/CustomPortfolioWatchList')));
// const AppStockDetails=Loadable(lazy(() => import('pages/apps/stocks/Stock-Details')));

const AppPortfolioWatchlistDetails=Loadable(lazy(() => import('pages/apps/watchlist/Portfolio-Details')));
const AppErrorPage = Loadable(lazy(() => import('pages/apps/watchlist/ErrorPage')));
const AppCreatePortfolioDetails=Loadable(lazy(() => import('pages/apps/invest/CreateNewPortfolio')));
const AppExternalAccountLink=Loadable(lazy(() => import('pages/apps/invest/ExtenalAccountLink')));
// render - forms & table

const FormsValidation = Loadable(lazy(() => import('pages/forms/validation')));
const FormsWizard = Loadable(lazy(() => import('pages/forms/wizard')));

const FormsLayoutBasic = Loadable(lazy(() => import('pages/forms/layouts/basic')));
const FormsLayoutMultiColumn = Loadable(lazy(() => import('pages/forms/layouts/multi-column')));
const FormsLayoutActionBar = Loadable(lazy(() => import('pages/forms/layouts/action-bar')));
const FormsLayoutStickyBar = Loadable(lazy(() => import('pages/forms/layouts/sticky-bar')));

const FormsPluginsMask = Loadable(lazy(() => import('pages/forms/plugins/mask')));
const FormsPluginsClipboard = Loadable(lazy(() => import('pages/forms/plugins/clipboard')));
const FormsPluginsRecaptcha = Loadable(lazy(() => import('pages/forms/plugins/re-captcha')));
const FormsPluginsEditor = Loadable(lazy(() => import('pages/forms/plugins/editor')));
const FormsPluginsDropzone = Loadable(lazy(() => import('pages/forms/plugins/dropzone')));

const ReactTableBasic = Loadable(lazy(() => import('pages/tables/react-table/basic')));
const ReactTableSorting = Loadable(lazy(() => import('pages/tables/react-table/sorting')));
const ReactTableFiltering = Loadable(lazy(() => import('pages/tables/react-table/filtering')));
const ReactTableGrouping = Loadable(lazy(() => import('pages/tables/react-table/grouping')));
const ReactTablePagination = Loadable(lazy(() => import('pages/tables/react-table/pagination')));
const ReactTableRowSelection = Loadable(lazy(() => import('pages/tables/react-table/row-selection')));
const ReactTableExpanding = Loadable(lazy(() => import('pages/tables/react-table/expanding')));
const ReactTableEditable = Loadable(lazy(() => import('pages/tables/react-table/editable')));
const ReactTableDragDrop = Loadable(lazy(() => import('pages/tables/react-table/drag-drop')));
const ReactTableColumnHiding = Loadable(lazy(() => import('pages/tables/react-table/column-hiding')));
const ReactTableUmbrella = Loadable(lazy(() => import('pages/tables/react-table/umbrella')));

// render - charts & map
const ChartApexchart = Loadable(lazy(() => import('pages/charts/apexchart')));
const ChartOrganization = Loadable(lazy(() => import('pages/charts/org-chart')));

// table routing
const MuiTableBasic = Loadable(lazy(() => import('pages/tables/mui-table/basic')));
const MuiTableDense = Loadable(lazy(() => import('pages/tables/mui-table/dense')));
const MuiTableEnhanced = Loadable(lazy(() => import('pages/tables/mui-table/enhanced')));
const MuiTableDatatable = Loadable(lazy(() => import('pages/tables/mui-table/datatable')));
const MuiTableCustom = Loadable(lazy(() => import('pages/tables/mui-table/custom')));
const MuiTableFixedHeader = Loadable(lazy(() => import('pages/tables/mui-table/fixed-header')));
const MuiTableCollapse = Loadable(lazy(() => import('pages/tables/mui-table/collapse')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/privacy-policy')));
const PrivacyPolicyCa = Loadable(lazy(() => import('pages/privacy-policy-ca')));
const TermsAndConditions = Loadable(lazy(() => import('pages/terms-and-conditions')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));

const StockInfo = Loadable(lazy(() => import('sections/apps/pmp/portfolio-details/stock-info-files/stock-info')));
const NationStatsAPICard = Loadable(lazy(() => import('sections/apps/pmp/portfolio-details/stock-info-files/MacroStatsTabs')));
const ExternalTradingAccount = Loadable(lazy(() => import('pages/apps/brokerBridge/TradingAccountList')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>

                    <MainLayout/>
                </AuthGuard>
            ),
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'default',
                            element: <DashboardDefault/>
                        },
                        {
                            path: 'analytics',
                            element: <DashboardAnalytics/>
                        }
                    ]
                },
                {
                    path: 'widget',
                    children: [
                        {
                            path: 'align',
                            element: <WidgetAlign/>
                        },
                        {
                            path: 'views',
                            element: <Views/>
                        },
                        {
                            path: 'exploreBlocks',
                            element: <ExploreBlocks/>
                        },
                        {
                            path: 'statistics',
                            element: <WidgetStatistics/>
                        },
                        {
                            path: 'data',
                            element: <WidgetData/>
                        },
                        {
                            path: 'chart',
                            element: <WidgetChart/>
                        }
                    ]
                },
            
                    
                          
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'chat',
                            element: <AppChat/>
                        },                        
                        {
                            path: 'calendar',
                            element: <AppCalendar/>
                        },                       
                        {
                            path: 'customer',
                            children: [
                                {
                                    path: 'list',
                                    element: <AppCustomerList/>
                                }
                            ]
                        },
                        {
                            path: 'stock/:symbol',
                            element: <StockInfo />

                        },
                        {
                            path: 'nationalStats/',
                             element: <NationStatsAPICard />

                        },                       
                        {
                            path: 'profiles',
                            children: [
                                {
                                    path: 'account',
                                    element: <AccountProfile/>,
                                    children: [
                                        {
                                            path: 'basic',
                                            element: <AccountTabProfile/>
                                        },
                                        {
                                            path: 'personal',
                                            element: <AccountTabPersonal/>
                                        },
                                        {
                                            path: 'my-account',
                                            element: <AccountTabAccount/>
                                        },
                                        {
                                            path: 'password',
                                            element: <AccountTabPassword/>
                                        },
                                        {
                                            path: 'role',
                                            element: <AccountTabRole/>
                                        },
                                        {
                                            path: 'settings',
                                            element: <AccountTabSettings/>
                                        }
                                    ]
                                },
                                
                                {
                                    path: 'user',
                                    element: <UserProfile/>,
                                    children: [
                                        {
                                            path: 'personal',
                                            element: <UserTabPersonal/>
                                        },
                                        {
                                            path: 'payment',
                                            element: <UserTabPayment/>
                                        },
                                        {
                                            path: 'password',
                                            element: <UserTabPassword/>
                                        },
                                        {
                                            path: 'settings',
                                            element: <UserTabSettings/>
                                        },
                                        {
                                            path: 'stripe',
                                            element: <StripeSettings/>
                                        }
                                    ]
                                },
                                {
                                    path: 'user-list',
                                    element: <ProfileUserList/>
                                },
                                {
                                    path: 'user-card',
                                    element: <ProfileUserCard/>
                                }
                            ]
                        },
                        {
                            path: 'pmp',
                            children: [
                                {
                                    path: 'portfolios',
                                    element: <AppPmpPortfolios/>
                                },
                                {
                                    path: 'portfolio-details/:id',
                                    element: <AppPmpPortfolioDetails/>
                                },
                                {
                                    path: 'portfolio-list',
                                    element: <AppPmpPortfolioList/>
                                },
                                {
                                    path: 'ChatAdvisor',
                                    element: <AppPmpChatAdvisor/>
                                },
                                {
                                    path: 'add-new-portfolio',
                                    element: <AppPmpAddPortfolio/>
                                },
                                {
                                    path: 'checkout',
                                    element: <AppPmpCheckout/>
                                },
                                {
                                path:'add-to-watchlist',
                                element:<AppPmpAddToWatchList/>
                                }
                            ]
                        },
                        {
                            path: 'invest',
                            children: [
                                
                                {
                                    path: 'CreateNewPortfolio',
                                    element: <AppCreatePortfolioDetails/>
                                },
                                {
                                    path: 'ExternalAccountLink',
                                    element: <AppExternalAccountLink/>
                                }
                            ]
                        },
                        {
                            path: 'watchlist',
                            children: [
                                
                                {
                                    path: 'Portfolio-Details/:id',
                                    element: <AppPortfolioWatchlistDetails/>
                                },
                                {
                                    path: 'ErrorPage',
                                    element: <AppErrorPage/>
                                },    
                                
                            ]
                        },  
                        {
                            path: 'brokerBridge',
                            children: [
                                
                                {
                                    path: 'TradingAccountList',
                                    element: <ExternalTradingAccount/>
                                },
                                   
                                
                            ]
                        },                            
                    ]
                },
                {
                    path: 'forms',
                    children: [
                        {
                            path: 'validation',
                            element: <FormsValidation/>
                        },
                        {
                            path: 'wizard',
                            element: <FormsWizard/>
                        },
                        {
                            path: 'layout',
                            children: [
                                {
                                    path: 'basic',
                                    element: <FormsLayoutBasic/>
                                },
                                {
                                    path: 'multi-column',
                                    element: <FormsLayoutMultiColumn/>
                                },
                                {
                                    path: 'action-bar',
                                    element: <FormsLayoutActionBar/>
                                },
                                {
                                    path: 'sticky-bar',
                                    element: <FormsLayoutStickyBar/>
                                }
                            ]
                        },
                        {
                            path: 'plugins',
                            children: [
                                {
                                    path: 'mask',
                                    element: <FormsPluginsMask/>
                                },
                                {
                                    path: 'clipboard',
                                    element: <FormsPluginsClipboard/>
                                },
                                {
                                    path: 're-captcha',
                                    element: <FormsPluginsRecaptcha/>
                                },
                                {
                                    path: 'editor',
                                    element: <FormsPluginsEditor/>
                                },
                                {
                                    path: 'dropzone',
                                    element: <FormsPluginsDropzone/>
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'tables',
                    children: [
                        {
                            path: 'react-table',
                            children: [
                                {
                                    path: 'basic',
                                    element: <ReactTableBasic/>
                                },
                                {
                                    path: 'sorting',
                                    element: <ReactTableSorting/>
                                },
                                {
                                    path: 'filtering',
                                    element: <ReactTableFiltering/>
                                },
                                {
                                    path: 'grouping',
                                    element: <ReactTableGrouping/>
                                },
                                {
                                    path: 'pagination',
                                    element: <ReactTablePagination/>
                                },
                                {
                                    path: 'row-selection',
                                    element: <ReactTableRowSelection/>
                                },
                                {
                                    path: 'expanding',
                                    element: <ReactTableExpanding/>
                                },
                                {
                                    path: 'editable',
                                    element: <ReactTableEditable/>
                                },
                                {
                                    path: 'drag-drop',
                                    element: <ReactTableDragDrop/>
                                },
                                {
                                    path: 'column-hiding',
                                    element: <ReactTableColumnHiding/>
                                },
                                {
                                    path: 'umbrella',
                                    element: <ReactTableUmbrella/>
                                }
                            ]
                        },
                        {
                            path: 'mui-table',
                            children: [
                                {
                                    path: 'basic',
                                    element: <MuiTableBasic/>
                                },
                                {
                                    path: 'dense',
                                    element: <MuiTableDense/>
                                },
                                {
                                    path: 'enhanced',
                                    element: <MuiTableEnhanced/>
                                },
                                {
                                    path: 'datatable',
                                    element: <MuiTableDatatable/>
                                },
                                {
                                    path: 'custom',
                                    element: <MuiTableCustom/>
                                },
                                {
                                    path: 'fixed-header',
                                    element: <MuiTableFixedHeader/>
                                },
                                {
                                    path: 'collapse',
                                    element: <MuiTableCollapse/>
                                }
                            ]
                        }
                    ]
                },
                {
                    path: 'charts',
                    children: [
                        {
                            path: 'apexchart',
                            element: <ChartApexchart/>
                        },
                        {
                            path: 'org-chart',
                            element: <ChartOrganization/>
                        }
                    ]
                },
                {
                    path: 'sample-page',
                    element: <SamplePage/>
                },
                {
                    path: 'pricing',
                    element: <PricingPage/>
                }
            ]
        },
        {
            path: '/maintenance',
            element: <CommonLayout/>,
            children: [
                {
                    path: '404',
                    element: <MaintenanceError/>
                },
                {
                    path: '500',
                    element: <MaintenanceError500/>
                },
                {
                    path: 'under-construction',
                    element: <MaintenanceUnderConstruction/>
                },
                {
                    path: 'coming-soon',
                    element: <MaintenanceComingSoon/>
                }
            ]
        },
        {
            path: '/auth',
            element: <CommonLayout/>,
            children: [
                {
                    path: 'login',
                    element: <AuthLogin/>
                },
                {
                    path: 'register',
                    element: <AuthRegister/>
                },
                {
                    path: 'forgot-password',
                    element: <AuthForgotPassword/>
                },
                {
                    path: 'reset-password',
                    element: <AuthResetPassword/>
                },
                {
                    path: 'check-mail',
                    element: <AuthCheckMail/>
                },
                {
                    path: 'code-verification',
                    element: <AuthCodeVerification/>
                }
            ]
        },
        {
            path: '/',
            element: <CommonLayout layout="simple"/>,
            children: [
                {
                    path: 'contact-us',
                    element: <AppContactUS/>
                }
            ]
        },
        {
            path: '/',
            element: <CommonLayout layout="simple"/>,
            children: [
                {
                    path: 'privacy-policy',
                    element: <PrivacyPolicy/>
                }
            ]
        },
        {
            path: '/',
            element: <CommonLayout layout="simple"/>,
            children: [
                {
                    path: 'privacy-policy-ca',
                    element: <PrivacyPolicyCa/>
                }
            ]
        },
        {
            path: '/',
            element: <CommonLayout layout="simple"/>,
            children: [
                {
                    path: 'terms-and-condition',
                    element: <TermsAndConditions/>
                }
            ]
        },
    ]
};

export default MainRoutes;
