import React, { Component } from 'react';
import Error404 from './404';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      componentStack: '',
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error occurred at URL:', window.location.href);

    const componentStack = errorInfo.componentStack || 'N/A';
    console.error('Error Object:', componentStack);
    console.error('Additional error info:', error, errorInfo);

    this.setState({ componentStack });

    this.postErrorToApi({
      error_message: error.message,
      error_url: window.location.href,
      error_object: componentStack,
      auth_token: '-', // Replace with your actual authentication token
      source:'FrontEnd',
    });
  }
  
  postErrorToApi = async (errorData) => {
    try {
      const apiUrl = 'https://api.versaquant.com:5000/log_error'; 
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        },
        body: JSON.stringify(errorData),
      });

      if (!response.ok) {
        console.error('Error posting to API:', response.statusText);
      }
    } catch (err) {
      console.error('Error posting to API:', err.message);
    }
  };

  render() {
    if (this.state.hasError) {
      return <Error404 componentStack={this.state.componentStack} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
