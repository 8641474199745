// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {
    LineChartOutlined,
    IdcardOutlined,
    DatabaseOutlined,
    VerticalAlignMiddleOutlined,
    GlobalOutlined,
    CustomerServiceOutlined,
    BlockOutlined,
    UserOutlined,
    MonitorOutlined, 
    FlagOutlined ,
    StockOutlined 
} from '@ant-design/icons';

// type
import {NavItemType} from 'types/menu';

// icons
const icons = {
    LineChartOutlined,
    IdcardOutlined,
    DatabaseOutlined,
    CustomerServiceOutlined,
    VerticalAlignMiddleOutlined,
    GlobalOutlined,
    BlockOutlined,
    UserOutlined,
    MonitorOutlined, 
    FlagOutlined ,
    StockOutlined 

};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget: NavItemType = {
    id: 'group-widget',
    // title: <FormattedMessage id="customer-alignment"/>,
    type: 'group',
    children: [
        {
            id: 'align',
            title: <FormattedMessage id="align"/>,
            type: 'item',
            url: '/widget/align',
            icon: icons.VerticalAlignMiddleOutlined
        },
        // {
        //     id: 'wealth-management-tools',
        //     title: <FormattedMessage id="wealth-management-tools"/>,
        //     type: 'collapse',
        //     icon: icons.GlobalOutlined,
        //     children: [
        //         {
        //             id: 'views',
        //             title: <FormattedMessage id="views"/>,
        //             type: 'item',
        //             url: '/widget/views',
        //             icon: icons.GlobalOutlined
        //         },
        //         // {
        //         //     id: 'account-profile',
        //         //     title: <FormattedMessage id="account-profile"/>,
        //         //     type: 'item',
        //         //     url: '/apps/profiles/account/basic'
        //         // },
        //     ]
        // },
        {
            id: 'customer',
            title: <FormattedMessage id="investment-tools"/>,
            type: 'collapse',
            icon: icons.StockOutlined  ,
            children: [
                // {
                //     id: 'customer-list',
                //     title: <FormattedMessage id="customer-list"/>,
                //     type: 'item',
                //     url: '/apps/customer/list'
                // },
                // {
                //     id: 'user-card',
                //     title: <FormattedMessage id="customer-card"/>,
                //     type: 'item',
                //     url: '/apps/profiles/user-card'
                // }
                {
                    id: 'exploreBlocks',
                    title: <FormattedMessage id="exploreBlocks"/>,
                    type: 'item',
                    url: '/widget/exploreBlocks',
                    icon: icons.BlockOutlined
                },
                {
                    id: 'stockResearch',
                    title: <FormattedMessage id="stockResearch"/>,
                    type: 'item',
                    url: '/apps/stock/AAPL',
                    icon: icons.MonitorOutlined
                },
                {
                    id: 'macroResearch',
                    title: <FormattedMessage id="macroResearch"/>,
                    type: 'item',
                    url: '/apps/nationalStats',
                    icon: icons.FlagOutlined
                },
            ]
        },
        {
            id: 'profile',
            title: <FormattedMessage id="profile"/>,
            type: 'collapse',
            icon: icons.UserOutlined,
            children: [
                {
                    id: 'user-profile',
                    title: <FormattedMessage id="user-profile"/>,
                    type: 'item',
                    url: '/apps/profiles/user/personal'
                },
                // {
                //     id: 'account-profile',
                //     title: <FormattedMessage id="account-profile"/>,
                //     type: 'item',
                //     url: '/apps/profiles/account/basic'
                // },
            ]
        },
        // {
        //     id: 'statistics',
        //     title: <FormattedMessage id="statistics"/>,
        //     type: 'item',
        //     url: '/widget/statistics',
        //     icon: icons.IdcardOutlined
        // },
        // {
        //     id: 'data',
        //     title: <FormattedMessage id="data"/>,
        //     type: 'item',
        //     url: '/widget/data',
        //     icon: icons.DatabaseOutlined
        // },
        // {
        //     id: 'chart',
        //     title: <FormattedMessage id="chart"/>,
        //     type: 'item',
        //     url: '/widget/chart',
        //     icon: icons.LineChartOutlined
        // }
    ]
};

export default widget;
