// third-party

// assets

// type
import {NavItemType} from 'types/menu';
import {FormattedMessage} from "react-intl";
import {
    CalendarOutlined,
    CustomerServiceOutlined,
    DashboardOutlined,
    MessageOutlined, ShopOutlined,
    ShoppingCartOutlined, UserOutlined
} from "@ant-design/icons";

const icons = {
    CalendarOutlined,
    DashboardOutlined,
    CustomerServiceOutlined,
    MessageOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    ShopOutlined
};


// icons
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard: NavItemType = {
    id: 'group-dashboard',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard"/>,
            type: 'item',
            icon: icons.ShopOutlined,
            url: '/dashboard/analytics'
        },
        //     children: [{
        //         id: 'portfolio-view',
        //         title: <FormattedMessage id="portfolio-view"/>,
        //         type: 'item',
        //         url: '/dashboard/analytics'
        //     },
        //         {
        //             id: 'macro-view',
        //             title: <FormattedMessage id="macro-view"/>,
        //             type: 'item',
        //             url: '/dashboard/default',
        //             breadcrumbs: false
        //         },
        //     ]
        // }
    ]
};

export default dashboard;
