// project import
import applications from './applications';
import dashboard from './dashboard';
import widget from './widget';
import {NavItemType} from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, applications,widget,   /*formsTables,*/ /*chartsMap,*/ /*pages,*/ /*other*/]
};

export default menuItems;
